.Container-Back-Image {
    text-align: left;
}

.Back-Image {
    max-height: 100%;
    height: 100%;
    width: 60%;
    bottom: 0;
    left: 0;
    margin: auto;
    overflow: auto;
    position: fixed;
    top: 0;
    z-index: 2;
}

.logo {
    float: right;
}

.container-form {
    margin: 5% 5% 12% 0%;
    float: right;
    display: grid;
    justify-items: end;
    grid-gap: 5%;
}

.text-welcome {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: right;
    color: #1E1E1E;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    float: right;
    width: 43%;
}

.form {
    width: 80%;
    font-family: 'Raleway';
}

.button-cancel {
    border-style: hidden !important;
    color: #0001A8 !important;
    width: 128px;
    border-radius: 20px !important;
}

.button-cancel:hover {
    background-color: transparent !important;
    border-style: solid #0001A8 !important;
    color: #0001A8 !important;
}

.button-acept {
    background-color: #0001A8 !important;
    width: 128px;
    border-radius: 20px !important;
}

.div-buttons {
    float: right;
}

.text-construction {
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 25.97%;
    font-family: 'Glory';
    font-style: normal;
    font-weight: 700;
    font-size: 102px;
    line-height: 114px;
    letter-spacing: -0.03em;
    color: #F5F5F5;
    text-align: end;
    margin: 0;
}

.text-universalseguros {
    left: 59.75%;
    right: 0.16%;
    top: 59.74%;
    bottom: 9.74%;
    font-family: 'Glory';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 20px;
    letter-spacing: -0.03em;
    color: #0001A8;
    text-align: end;
    margin: 0;
}

.text-co {
    left: 95.48%;
    right: 0.16%;
    top: 81.17%;
    bottom: 0%;
    font-family: 'Glory';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 29px;
    letter-spacing: -0.03em;
    color: #02E9F2;
    text-align: end;
    margin: 0;
}

@media screen and (max-width: 1450px) {
    .form {
        width: 50%;
    }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 920px) {
    .form {
        width: 40%;
    }
}

@media only screen and (max-device-width: 768px) {
    /* Styles */
}