.Container-Footer {
    box-sizing: border-box;
    position: fixed;
    height: 51px;
    width: 100%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    background: #0001A8;
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: list-item;
}

.text-footer {
    text-align: end;
    padding-right: 20%;
    font-family: 'Glory';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: -0.03em;
    color: #FFFFFF;
    margin-top: revert;
}